.card {
  display: inline-block;
  width: 100%;
  max-width: 352px;
  margin: 16px;

  &__wrap-in {
    padding: 3.7em 3em;
    background-color: $white;
    overflow: hidden;
    @include border-radius(4px);
    @include box-shadow(0 4px 20px 0 rgba(0,0,0,0.05));
  }
  &__title {
    margin: 0;
    font-size: 30px;
    color: $color-card-title;
    @include font-weight(strong);
  }
  &__list-group {
    margin: 36px auto;

    > li {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.71;
    }
  }
}

@media (max-width: $screen-phone) {
  .card {
    max-width: 280px;
  }
}