.panel-list-group {
  &__fluid {
    width: 100%;
    margin-bottom: 24px;
  }
  &__title {
    margin-bottom: 16px;
    @include font-weight(strong);
  }
}

.panel {
  &__custom {
    color: $grey-dark;
    @include border-radius(4px);
    @include transition(ease-in 0.2s all);

    &--bordered {
      border: 1px solid $grey-default;
    }

    .panel-heading {
      padding: 0;

      .panel-title {
        p {
          margin: 0;
        }
      }
    }

    a[role="button"] {
      display: flex;
      justify-content: flex-start;
      padding: 20px 16px 20px 25px;
      font-size: 14px;
      p {
        width: 100%;
      }
      span {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;

        >i {
          line-height: 1;
          @include transition(ease-in 0.2s all);
          @include rotate(270deg);

          &.spoticon-default {
            @include rotate(0deg);
          }
        }
      }      
      &:hover,
      &:focus {
        text-decoration: none;
        color: $grey-dark;
      }
    }

    a[role="button"].collapsed {
      span {
        > i {
          @include rotate(0deg);
        }
      }
    }

    &:hover,
    &:focus {
      @include box-shadow(0 3px 5px 0px rgba(0,0,0,0.3));
    }
  }
}

.panel-group {
  .panel-heading {
    +.panel-collapse>.panel-body,
    +.panel-collapse>.list-group {
      padding: 10px 25px 30px 25px;
      font-size: 14px;
      line-height: 21px;
      border-top: none;
    }
  }
}