.button {
  display: inline-block;
  padding: 9px 27px;
  color: $white;
  line-height: 1.43;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  z-index: 1;
  @include transition(all .15s ease-in);

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
    @include box-shadow(0 3px 5px 0px rgba(0,0,0,0.3));
  }
  // Mini button
  &--mini {
    width: 90px;
    font-size: 14px;
    color: $white;
    @include font-weight(strong);
  }
  // Small button
  &--small {
    font-size: 14px;
    @include font-weight(strong);
  }
  // Large button
  &--large { }
  &--block { 
    width: 100%;
  }
  // Primary button default
  &--primary {
    @extend %spot__gradient;
  }
  &--secondary {
    background-color: $color-secondary-button;
  }
  // Flat button default
  &--flat {
    color: $alert-danger;
    background-color: $white;
    &:hover,
    &:focus {
      color: $alert-danger;
    }
  }
  // Rounded button default
  &--rounded {
    @include border-radius(2em);
  }
  // Outline button
  &--outline {
    width: auto;
    margin-top: 8px;
    margin-left: 16px;
    padding: 6px 15px !important;
    border: solid 2px $white;
  }
  // Transparent button
  &--transparent {
    padding: 16px;
    margin-left: -16px;
    background: transparent;
    &:focus,
    &:active {
      opacity: .9;
    }
  }
  // File upload button
  &--file {
    color: $grey-dark;
    &:hover,
    &:active,
    &:focus {
      color: $grey-dark;
      border: 1px dashed $color-link;
      @include box-shadow(none);
    }
  }
  // Top right button (close)
  &--top-right {
    position: absolute;
    top: 2em;
    right: 2em;
    color: #999;
    &:hover,
    &:active,
    &:focus {
      opacity: .9;
      color: #999;
    }
    @include breakpoint(tablet) {
      top: 8px;
      right: 0;
    }
  }
  // Flat panel button
  &--panel {
    position: relative;
    color: $grey-dark;
    text-align: left;

    > i {
      position: absolute;
      top: 50%;
      right: 1em;
      line-height: 9px;
      @include translate(0, -50%);
    }

    &:hover,
    &:active,
    &:focus {
      color: $grey-dark;
    }
  }

  // Icons button
  &--icon {
    color: $alert-danger;
    font-size: 16px;
    padding-right: 0;
    padding-left: 0;

    img {
      margin-right: 16px;
      padding-bottom: 4px;
    }

    &:hover,
    &:active,
    &:focus {
      color: $alert-danger;
      @include box-shadow(none);
    }
  }
}

.button-group {
  margin-bottom: 4rem;
  @include clearfix;
}

#buttonClose {display: none;}

@media (max-width: $screen-tablet) {
  #buttonClose {
     display: block;
  }
}

input[type="file"] {
  display: none;
}

.btn--file {
  float: left;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  background-color: $white;
  color: $grey-dark;
  &:hover,
  &:focus,
  &:active {
    color: $grey-dark;
  }

  + label {
    margin: 16px 0;
    background-color: $white;
    border: none;
    color: $grey-dark;

    &:hover,
    &:focus,
    &:active {
      color: $grey-dark;
    }

    > i.spoticon-attach {
      font-size: 13px;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
}

.btn-file--gallery {
  i {
    &:before {
      margin-right: 4px;
      display: inline-block;
      @include translate(0, 1px);
    }
  }
}

.spot-button-group {
  .spot-button-group__inline {
    display: inline-block;
    width: 49%;
    &--default{
      display: inline-block;
    }
  }
}

a[role="button"] {
  &.spot-button {
    // padding: 12px 0;
    i.icon {
      font-size: 11px;
    }
  }
}

// Buttons group for controlling croppper
.btn--zoom {
  display: inline-block;
  padding: 0px 6px;
  background-color: $color-sidebar;
  @include border-radius(3px);

  .icon {
    font-size: 10px;
    vertical-align: middle;
  }
}
