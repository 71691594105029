.content__wrapper {
  width: 100%;
  max-width: 523px;
  margin: auto;

  &--fluid {
    max-width: 100%;
  }

  .content {
    &__title {
      margin-top: 5em;
      @extend %title;      
      &--white {
        margin-top: 0;
        color: $white;
      }
      &--bordered {
        &:after {
          content: "";
          display: block;
          width: 30px;
          margin: 1.2rem auto 0;
          border-bottom: 2px solid #9d2700;
        }
      }   
    }
    &__text {
      @extend %text;
      &--white {
        color: $white;
      }
    }
  }
}

.feature {
  &__wrapper { }
  &__title {
    font-size: 18px !important;
    @extend %title;
  }
  &__text {
    @extend %text;
  }
}