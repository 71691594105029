.swiper-container {
  width: 100%;
  max-width: 1280px;
  .swiper-wrapper {
    .swiper-slide {
      .slide-main {
        position: relative;
        max-width: 952px;
        margin: auto;
      }
    }
  }
  @include breakpoint(tablet) {
    height: auto;
  }
}

// Arrow custom
.swiper-button-prev, .swiper-button-next {
  color: $white;
}
.swiper-button-next, 
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: none;
  background-color: rgba(0,0,0, 0.5);
  width: 40px;
  height: 40px;
  @include border-radius(3px);
}
.swiper-button-next, 
.swiper-container-rtl .swiper-button-prev {
  right: 10%;
  @include breakpoint(mobile-large) {
    right: 3%;
  }
}
.swiper-button-prev, 
.swiper-container-rtl .swiper-button-next {
  left: 10%;
  @include breakpoint(mobile-large) {
    left: 3%;
  }
}
.swiper-spoticon {
  position: absolute;
  font-size: 18px;
  line-height: 16px;
  @include translate(-50%, -50%);
  &.spoticon-arrow-right-dark {
    top: 50%;
    left: 50%;
  }
  &.spoticon-arrow-left-dark {
    top: 50%;
    left: 50%;
  }
}
