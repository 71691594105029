.form-group__newsletter {
  position: relative;
  width: 360px;
  margin: auto;
  .button {
    position: absolute;
    top: 0;
    right: 0;
  }
  // responsive
  @include breakpoint(tablet) {
    width: auto;
  }
}
label {
  font-weight: normal;
}

// Sign Up Form
#signUp {
  .form-group{
    .label-fluid,
    .label-inline {
      margin-top: 0;
    }
  }
}

// Textarea styling
.spot-textarea {
  @extend .spot-input__text;
  min-height: 104px;
  overflow-y: hidden;
  resize: none;
}