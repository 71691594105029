.list-group__description {
  margin-bottom: 3em;
  p {
    margin-bottom: 16px;
  }
  li {
    list-style-type: disc;
    margin-left: 28px;
    margin-bottom: 8px;
  }
}

* {
  margin: 0;
  padding: 0;
  @include box-sizing(border-box);
}

.flex-list {
  display: flex;
  flex-direction: column;

  >li {
    line-height: 28px;
    font-size: 14px;

    a {
      color: $color-grey-900;
      &.active {
        font-weight: 600;
        color: $color-spot-alert;
      }
    }
  }
}