.section__footer {
  .footer__icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-top: 16px;
    margin-right: 16px;
  }
  p {
    font-size: 13px;
  }
  .footer__list-group {
    @include breakpoint(tablet) {
      text-align: center;
      margin-top: 2em;
    }
    .footer__list-title {
      font-family: $font-primary-bold;
      font-size: 14px;
      margin-bottom: 16px;
      @include breakpoint(tablet) {
        font-size: 16px;
      }
    }
    .footer__list-text {
      &--calm {
        color: #337ab7;
        @extend .footer__list-title;
      }
      &--warn {
        color: $alert-danger;
        @extend .footer__list-title;
      }
    }
    ul {
      > li {
        line-height: 24px;
        p {
          margin-bottom: 8px;
          font-size: 11px;
          font-weight: bold;
          > a {
            font-size: 11px;
            color: $grey-dark;
          }
        }
      }
    }
  }
}