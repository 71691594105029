.text-color {
  &--primary {
    color: $color-spot-orange;
  }
}

.title-wrap {
  &--bordered {
    border-bottom: 1px solid $color-spot-grey;
  }
}
.title-sidebar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 27px;
  margin: 0;
  font-size: 16px;
  color: $color-grey-900;

}
.title-sidebar--strong {
  margin: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 900;
  color: $color-grey-900;
}