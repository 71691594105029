.spot-navbar {
  width: 100%;
  max-width: 1170px;
  padding: 14px 0;
  margin: 0 auto;
  background-color: transparent;
  @media (min-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar-nav {
    > li {
      > a {
        color: $white;
        outline: none;
        &:hover,
        &:focus,
        &:visited {
          background-color: transparent;
        }
      }
    }
  }
  .navbar-right {
    @media (min-width: $grid-float-breakpoint) {
      margin: 0;
    }
  }
}
.navbar {
  border: none;
  margin-bottom: 0;
  @include transition(ease-in 0.15s all);

  &.is-transparent {
    background-color: transparent;
    border: none;
  }
  &.is-fade {
    opacity: 0;
    @include translate(0, -80px);
    &.is-shrink {
      opacity: 1;
      @extend %spot__gradient;
      @include translate(0, 0);
    }
  }
}
.navbar-toggle {
  .icon-bar {
    background-color: $white;
  }
}
.navbar-collapse {
  border-top: none;
  @include box-shadow(none);
}

@media (max-width: 767px) {
  .navbar-nav {
    margin: 10rem 0 .8rem;
    li {
      > a { text-align: center;}
    }
    li:last-child {
      .button--outline {
        margin-top: 16px;
        margin-left: 0;
      }
    }
  }
  .navbar-logo {
    padding-left: 16px;
    float: left;
  }
}
.navbar-logo-wrapper {
  display: inline-block;
}

.img {
  &--small {
    max-width: 140px;
  }
}

// Navbar Drawer
// 
// ----------------------------------

.navbar-drawer {
  min-height: 52px;
}

@include breakpoint(tablet) {
  .spot-navbar {
    background-color: inherit;
  }
  .navbar-toggle {
    margin-top: 0;
    margin-bottom: 0;
    padding: 9px;
    @include transition(ease-in .15s);

    &:active{
      border-radius: 5em;
      background: rgba(0,0,0,0.2);
    }
  }
  .navbar-drawer {
    position: fixed;
    width: 300px;
    height: 100vh;
    top: 0;
    right: 0;
    background: #122442;
    padding: 0 24px;
    @include box-shadow(0 0 20px 1px rgba(0,0,0,0.5));
    @include transition(all .2s ease-in-out);
    @include translate(320px, 0);
    
    &.drawer-in {
      @include translate(0, 0);
    }
  }
}
.overlay {
  background-color: rgba(0,0,0,0.25);
  overflow: hidden;
}