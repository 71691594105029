//
// Jumbotron
// --------------------------------------------------


.jumbotron {
  padding-top:      0;
  padding-bottom:   0;
  margin-bottom:    0;
  // margin-bottom:    $jumbotron-padding;
  color:            $jumbotron-color;
  background-color: transparent;

  .jumbotron__wrapper {
    display: block;
    width: 100%;
    background: url("../images/banner@2x.jpg") no-repeat center;
    background-size: cover;

    &--main {
      height: auto;
      @include breakpoint(tablet) {
        height: auto;
      }
    }

    &--secondary {
      min-height: 475px;
    }

    .jumbotron__text-group {
      padding: 15rem 0 2rem;
      color: $white;
      
      .jumbotron__title {
        max-width: 453px;
        margin: 0 auto;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        line-height: 1.42;
        @include breakpoint(mobile-medium) {
          font-size: 22px;
        }
      }

      .jumbotron__text {
        &--large {
          font-size: 24px;
          font-weight: bold;
        }
        &--mini {
          font-size: 14px;
        }
      }

      .jumbotron__container-text {
        max-width: 670px;
        margin: 40px auto 0;
      }
    }
  }

  h1,
  .h1 {
    color: $jumbotron-heading-color;
  }

  p {
    margin-bottom: ($jumbotron-padding / 2);
    font-size: $jumbotron-font-size;
    font-weight: 200;
  }

  > hr {
    border-top-color: darken($jumbotron-bg, 10%);
  }

  .container &,
  .container-fluid & {
    border-radius: $border-radius-large; // Only round corners at higher resolutions if contained in a container
    padding-left:  ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }

  .container {
    max-width: 100%;
  }

  @media screen and (min-width: $screen-sm-min) {
    padding-top:    0;
    padding-bottom: 0;
    // padding-top:    ($jumbotron-padding * 1.6);
    // padding-bottom: ($jumbotron-padding * 1.6);

    .container &,
    .container-fluid & {
      padding-left:  ($jumbotron-padding * 2);
      padding-right: ($jumbotron-padding * 2);
    }

    h1,
    .h1 {
      font-size: $jumbotron-heading-font-size;
    }
  }
}
