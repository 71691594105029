.input-type-group {
  position: relative;
  @include clearfix;
}
.input {
  width: 100%;
  display: inline-block;
  padding: 9px 18px;
  outline: none;  
}
.input {
  &__text {
    font-size: 14px;
    border: none;
    &--small {
      font-size: 13px;
      padding-top: 8px;
      padding-right: 36px;
      padding-left: 16px;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border: 1px solid #999;
      @include border-radius(4px);
    }
  }
  &--text-rounded {
    @include border-radius(5em);
    @include breakpoint(tablet) {
      padding: 9.5px 18px;
    }
  }
  &--text-block {
    margin-bottom: 16px;
    padding: 24px 70px;
    background-color: $white;
    @include border-radius(4px);
    @include breakpoint(mobile-medium) {
      padding: 24px 0 24px 60px;
    }
  }
  &--text-default {
    border: 1px solid $grey-default;
    padding: 24px 30px;
    @extend .input--text-block;
  }
  &--text-search {
    background: $white url('../images/icon-search.svg') no-repeat 28px center;
    background-size: 20px;
  }
  &--error {
    border: 2px solid $alert-danger;
  }
  &__corner {
    &--left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &--right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &--left,
    &--right {
      @include breakpoint(mobile-large) {
        border-radius: 4px;
      }
    }
  }
  &:hover,
  &:active,
  &:focus {
    @include box-shadow(none);
  }
}
.textarea {
  font-size: 14px;  
  &__fluid {
    width: 100%;
    display: inline-block;
  }
  &--default {
    min-height: 200px;
    outline: none;
    resize: none;
    @extend .input--text-default;
  }
}

.form-user {
  .input {
    &--text-block {
      padding: 24px;
    }
  }
}