.label {
  padding: .7em .6em .8em;
  font-size: 11px;
  
  &.spot-label--success {
    display: block;
    background: $alert-success;
  }
  &.spot-label--default {
    display: block;
    // background: $alert-success;
  }
}

.label-text {
  &--mini {
    font-size: 13px;
    @include font-weight(strong);
  }
}

.label-fluid {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 28px;

  .icon {
    &__position-right {
      position: absolute;
      top: 2.1em;
      right: 6px;
      padding: 6px;
      color: $grey-light;
      cursor: pointer;
    }
  }
}

.label-inline {
  position: relative;
  display: block;
  float: left;
  width: 50%;
  margin-top: 16px;

  @include breakpoint(mobile-large) {
    width: 100%;
  }

  .icon {
    &__position-right {
      position: absolute;
      top: 2.1em;
      right: 6px;
      padding: 6px;
      color: $grey-light;
      cursor: pointer;
    }
  }
}

.label-inline-group {
  @include clearfix;
}