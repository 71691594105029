.panel {
  &.panel--transparent {
    @include box-shadow(none);
  }
  .panel-body {
    padding: 0;
  }
}

.nav {
  &.nav-tabs {
    display: flex;
    border-bottom: none;
    @include breakpoint(tablet) {
      width: 100%;
    }
    li,
    li.active {
      a {
        display: block;
        padding: 10px 0;
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-weight: 600;
        color: $border-grey-strong;
        @include breakpoint(mobile-medium) {
          font-size: 12px;
        }   

        &.card-tab {
          width: 192px;
          height: 172px;
          padding-top: 9.5em;
          padding-bottom: 0;
          background: #fff;
          text-align: center;
          border: 1px solid #e6e9ee;
          @include border-radius(4px);
          @include box-shadow(2px 2px 12px 0 rgba(203, 211, 226, 0.15));
          @include breakpoint(mobile-medium) {
            padding-top: 11.5em;
          } 

          &--faq {
            background: url('../images/faq.svg') no-repeat center 16px;
            background-color: $white;
          }
          &--contact {
            background: url('../images/contact-us.svg') no-repeat center 16px;
            background-color: $white;
          }

          // responsive
          @include breakpoint(tablet) {
            width: auto;
          }
        }
      }
      &.active {
        a {
          color: $color-grey-900;
          cursor: pointer;
          outline: none;
        }
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          display: block;
          width: 60px;
          margin: auto;
          border-bottom: 4px solid $alert-danger;
          @include border-radius(3em);
          @include translate(-50%, 0);
        }
      }
    }

    li.tabs-item--fit,
    li.tabs-item--fit.active {
      a {
        @include breakpoint(mobile-medium) {
          font-size: 18px;
        }
      }
    }

    li {
      margin-right: 10px;
      margin-left: 10px;

      &.tabs-item {
        &--fit {
          &.active {
            &:after {
              content: '';
              position: absolute;
              left: 50%;
              display: block;
              width: 100%;
              margin: auto;
              border-bottom: 4px solid $alert-danger;
              @include border-radius(3em);
              @include translate(-50%, 0);
            }
          }
          @include breakpoint(mobile-medium) {
            margin-right: 20px;
            margin-left: 0;
            width: auto;
          }
        }
      }
    }

    &--centered {
      li {
        @include breakpoint(tablet) {
          width: 50%;
        }
      }
    }
    &--custom {
      > li:last-child {
        margin-left: 3rem;
      }
      li,
      li.active {
        a {
          padding: 10px 0;
          margin: 0;
          text-indent: 0;
          background-color: transparent;
          border: none;
          font-size: 21px;
          font-weight: 600;
          color: $border-grey-strong;
        }
        &.active {
          a {
            color: $color-grey-900;
            cursor: pointer;
            outline: none;
          }
          &:after {
            content: '';
            @include border-radius(0);
          }
        }
      }
    }
    .badge {
      margin-top: -3px;
      margin-left: 3px;
      &.badge-warning {
        background-color: $color-selected-table;
      }
    }
  }
}

.nav-tabs--inline {
  display: inline-block;
}

.nav-tabs--centered {
  position: absolute;
  display: inline-block;
  top: -80px;
  left: 50%;
  @include translate(-50%, 0);
}

.tab-content {
  padding-top: 5em;
}