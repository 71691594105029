.section {
  &__wrapper {
    position: relative;
    padding: 5rem 0 5rem;
    @include clearfix;
    @include breakpoint(phablet) {
      padding: 2em 0;
    }

    &--bottom-spacer {
      position: relative;
      margin-bottom: 24px;

      @include clearfix;
    }

    .section__text {
      &--default {
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
      }
    }

    ul.section__list {
      padding-left: 18px;
      list-style-type: decimal;
      font-size: 14px;
      line-height: 24px;
      text-align: justify;

      .list-item {
        text-indent: 0;
      }
      ul.section__list {
        list-style-type: lower-alpha;
        padding-left: 1.2em;
        margin: 10px 0;
        ul.section__list {
          list-style-type: disc;
          margin: 10px 0;
        }
      }
    }
  }
  &__wrapper-default {
    position: relative;
    @include clearfix;

    .section__main {
      margin-left: 130px;
      @media (min-width: 768px) {
        margin-left: 4vw;
      }
      @include breakpoint(tablet) {
        margin-left: 0;
      }
    }
  }
  &__wrapper-alert {
    padding: 3rem 0 1rem;
  }
  &__main {
    height: 100vh;
    // overflow: auto;
    &--title {
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 20px;
      @include font-weight(strong);
    }
    &--text-shade {
      font-size: 13px;
      color: #888;
    }
    &--theme {
      height: 100%;
      background: url('../images/web@2x.jpg') no-repeat center;
      background-size: cover;
    }
    &--centered {
      max-width: 425px;
      margin: auto;
    }
  }
  &--theme-soft-sun {
    width: 100%;
    height: 100%;
    min-height: 640px;
    background-image: linear-gradient(to bottom, rgba(201, 90, 164, 0), rgba(240, 97, 49, 0.2));
  }
  &--theme-bg {
    height: 240px;
    background: url("../images/bckgrnd-submitemail@2x.jpg") no-repeat center;
    background-size: cover;
  }
  &--align-center {
    // position: absolute;
    // top: 50%;
    width: 425px;
    padding-top: 50px;
    // @include translate(0, -50%);
    @include breakpoint(tablet) {
      width: 100%;
      top: 2.5rem;
      left: 50%;
      // @include translate(-50%, 0);
    }
  }
  &__footer {
    min-height: 305px;
    // background-color: $white;
  }
}
.page__feature {
  overflow: hidden;
  &--text {
    margin: 3em 3em;
    @include breakpoint(desktop-medium) {
      margin: 0 3em;
    }
    @include breakpoint(tablet) {
      margin: 0 1em;
      text-align: center;
    }
  }
  .page {
    &__title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 36px;
      color: $grey-dark;
    }
    &__text {
      font-size: 18px;
      line-height: 27px;
      @include breakpoint(phablet) {
        font-size: 14px;
      }
    }
  }
}

.flex-content {
  display: flex;
  align-content: space-between;
  align-items: center;
  justify-content: center;

  @include breakpoint(mobile-medium) {
    display: block;
  }
}

.section-bordered {
  &--left {
    padding-left: 40px;
    border-left: 1px solid $color-spot-grey;
  }
}