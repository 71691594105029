@import "../themes/_palette.scss";

body {
  font-family: $font-primary;
  font-size: 16px;
  color: $color-grey-900;
}
.spot-bg {
  background-color: $grey-light-blue;
}
.text__muted--top {
  font-size: 13px;
  margin-bottom: 0;
  color: $color-grey-900;
}
.text__based {
  font-size: 28px;
  line-height: 1.32;
  margin: 0 0 4px;
  @include font-weight(strong);
}
.text__state {
  &--success {
    font-size: 11px;
    @include font-weight(strong);
    color: $alert-success;
  }  
  &--alert {
    font-size: 11px;
    @include font-weight(strong);
    color: $alert-danger;
  }
  &--disable {
    color: $color-grey-border;
    @include font-weight(light);
  }
  &--info {
    color: $color-mini-info;
    font-style: italic;
    @include font-weight(light);
  }
}
.text--strong {
  color: $color-grey-900;
  @include font-weight(strong);
}
.text--warning {
  color: $alert-danger;
}
.text--capital {
  text-transform: uppercase;
}
.is-hide {
  display: none;
}
.is-hide-visible {
  visibility: hidden;
}
.spot-list-group {
  margin-bottom: 0;
}
ul,
ol {
  list-style: none;
}
.page-container {
  margin-top: 77px;
}
.panel--transparent {
  background-color: transparent;
}
.spot-label--full {
  @include element-width(full-width);
}
.spot-label--inline {
  @include element-width(inline);
}

// Modal styling
.spot-modal__content {
  background: $color-grey-20;

  .spot-label__container {
    @extend .spot-label__container;
    > p {
      @include font-size(small);
    }
  }  
  .modal-header {
    padding: 24px 24px 32px;
  }
  .spot-modal__body {
    padding: 0 24px;
  }
  .modal-footer {
    padding: 0 24px 24px;
  }
}

.flex-wrap {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  
  @include breakpoint(phablet) {
    margin-bottom: 18px;
  }

   &--centered {
    justify-content: center;
  }

}

.flex-wrap-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  .flex-wrap-item {
    display: inline-flex;
    align-items: flex-end;
  }
}